import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter, dateFilter, } from "react-bootstrap-table2-filter";
import { MdAdd, MdEdit, MdOutlineDelete } from "react-icons/md";
import { toast } from "react-toastify";
import edMantra from "../../services/edMantra";
import _ from "lodash";
import { SiMicrosoftexcel } from "react-icons/si";
import paginationFactory from 'react-bootstrap-table2-paginator';
import loadinggif from "../../assets/images/loading.gif";
import { FaAddressCard } from "react-icons/fa";
import { useSelector } from "react-redux";
import ToolkitProvider, { Search, CSVExport, pagination } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
const CandidateOnboarding = (props) => {
    const { data } = useSelector((state) => state?.currentSession?.userInfo);
    const [showModal, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [isEdit, setIsEdit] = useState(false);
    const [allCandidate, setAllCandidate] = useState([]);
    const [balance, setBalance] = useState(null);
    const [allInterview, setAllScheduledInterview] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [interview, setInterView] = useState({
        interviewDate: "",
        candidateName: "",
        companyName: "",
        jobProfile: "",
        candidateStatus: "",
        joiningDate: "",
        consultAmount: 0,
        recvAmount: 0,


    });
    const handleClose = () => {
        setShow(false);
        setIsEdit(false);
        setInterView({
            interviewDate: "",
            candidateName: "",
            companyName: "",
            jobProfile: "",
            candidateStatus: "",
            joiningDate: "",
            consultAmount: 0,
            recvAmount: 0,
        });

    }

    const formatDateforleadinout = (dateString) => { //for lead craetedAt and updatedAt
        const date = new Date(dateString);
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true // Include this option for 12-hour format
        };
        return date.toLocaleDateString('en-GB', options);
    }

 // Custom CSS for blinking effect
 const styles = `
 @keyframes blink {
   0% { opacity: 1; }
   50% { opacity: 0; }
   100% { opacity: 1; }
 }
`;

    const { ExportCSVButton } = CSVExport;
    const columns = [

        {
            dataField: "candidateName",
            text: "Candidate Name",
            sort: "true",
            filter: textFilter(),
        },


        {
            dataField: "phone",
            text: "Candidate Name",
            sort: "true",
            filter: textFilter(),
        },
        {
            dataField: "email",
            text: "Email",
            filter: textFilter(),
        },
        {
            dataField: "companyName",
            text: "Company Name",
            sort: "true",
            filter: textFilter(),
        },

        {
            dataField: "jobProfile",
            text: "Job Profile",
            sort: "true",
            filter: textFilter(),
        },
        {
            dataField: "interviewDate",
            text: "Interview Date",
            sort: "true",
            filter: dateFilter(),
            formatter: (cell, row) => formatDateforleadinout(cell)
        },

        {
            dataField: "candidateStatus",
            text: "Candidate Status",
            sort: "true",
            filter: textFilter(),
        },

        {
            dataField: "action",
            text: "Action",
            formatter: (cell, row) => (
                <div className="d-flex">
                    {_.isNil(row.consultAmount) ? <MdAdd
                        size={25}
                        color="green"
                        style={{ cursor: "pointer", marginRight: "10px" }}
                        onClick={() => handleAdd(row)}
                    /> : <MdEdit
                        size={25}
                        color="black"
                        style={{ cursor: "pointer", marginRight: "10px" }}
                        onClick={() => handleAdd(row)}
                    />}




                </div>
            ),
        },
    ];

    const handleOnchange = (value, valueField) => {
        setInterView((prev) => ({
            ...prev,
            [valueField]: value,
        }));
    };


    const getCandidatebyStatus = async () => {
        try {
            const result = await edMantra.getCandidatebyStatus({ empId: data?.empId });
            setAllCandidate([]);
            if (result?.data?.errorCode === 0) {
                setAllCandidate(result?.data?.data);
            }
        } catch (error) {
            console.error(error);
        }
    };



    const getAllScheduledInterview = async () => {
        try {
            const result = await edMantra.getAllScheduledInterview({ empId: data?.empId });
            setAllScheduledInterview([]);
            if (result?.data?.errorCode === 0) {
                setAllScheduledInterview(result?.data?.data);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleAddOnboarding = async () => {
        try {
            const postData = { ...interview, balance: Number(interview?.consultAmount) - Number(interview?.recvAmount) }
            const res = await edMantra.addOnboarding(postData);
            if (res?.data?.errorCode === 0) {
                toast.success(res?.data?.message);
                getCandidatebyStatus();

                setBalance(null);
                getAllScheduledInterview();
                setShow(false);
                setInterView({
                    interviewDate: "",
                    candidateName: "",
                    companyName: "",
                    jobProfile: "",
                    candidateStatus: "",
                    joiningDate: "",
                    consultAmount: 0,
                    recvAmount: 0,
                });
            }
        } catch (error) {
            console.error(error);
        }

    };

    const handleSubmit = async () => {
        try {
            const postData = { ...interview, balance: Number(interview?.consultAmount) - Number(interview?.recvAmount) }
            const result = await edMantra.updateOnboarding(postData);
            if (result?.data?.errorCode === 0) {
                toast.success(result?.data?.message);
                getCandidatebyStatus();

                setBalance(null);
                getAllScheduledInterview();
                setShow(false);
                setInterView({
                    interviewDate: "",
                    candidateName: "",
                    companyName: "",
                    jobProfile: "",
                    candidateStatus: "",
                    joiningDate: "",
                    consultAmount: 0,
                    recvAmount: 0,
                });
            }
        } catch (error) {
            console.error(error);
        }


    };

    const handleAdd = (row) => {

        setBalance(row.balance)
        setInterView(row);
        setShow(true);
        setIsEdit(true);
    };


// Function to handle row selection
const handleRowSelect = (row, isSelected) => {

    setSelectedRows([...selectedRows, row])


  }; console.log(selectedRows)

  // Function to handle row selection for all rows
  const handleRowSelectAll = (isSelected, rows) => {
    // Update selectedRows state when all rows are selected or deselected
    console.log(rows)
    if (isSelected) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };


    useEffect(() => {
        getCandidatebyStatus();

        getAllScheduledInterview();
    }, []);


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 card border-0">
                        <p className="fs-3 fw-normal typed-text">Candidate Onbaording</p>
                    </div>



                    <div className="card col-md-12 border-0">
                        {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={loadinggif} alt="Loading" />
                            </div>
                        ) : (
                            <ToolkitProvider
                                keyField="SrNo"
                                data={allCandidate}
                                columns={columns}
                                exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                                search
                            >
                                {
                                    props => (
                                        <div>
                                            <ExportCSVButton className="btn btn-success btn-sm mb-2 float-end" {...(props.csvProps || {})} filename="MantrasERP_Record.csv">< SiMicrosoftexcel /> Export Record</ExportCSVButton>

                                            <div className="table-responsive cus-table">
                                                <style>{styles}</style> {/* Inject the blinking effect CSS */}
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    selectedRows={selectedRows}
                                                    filter={filterFactory()}
                                                    bootstrap4
                                                    keyField="SrNo"
                                                    data={allCandidate}
                                                    columns={columns}
                                                    striped
                                                    hover
                                                    condensed
                                                    pagination={paginationFactory({ showTotal: true, })}
                                                    selectRow={{
                                                        mode: 'checkbox',
                                                        clickToSelect: true,
                                                        onSelect: handleRowSelect,
                                                        onSelectAll: handleRowSelectAll,
                                                    }}
                                                    filterPosition="top"
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </ToolkitProvider>
                        )}
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Candidates Onboarding Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
                    <h6 className="custom-h6">Onboarding Details</h6>
                    <div className="row">

                        <div className="col-md-6">
                            <p className="mb-0 fs-6">Candidate Name</p>


                            <input
                                type="text"
                                name=""
                                id=""
                                className="form-control"
                                value={interview.candidateName}
                                disabled

                            />



                        </div>
                        <div className="col-md-6">

                            <p className="mb-0 fs-6">Company Name</p>
                            <input
                                type="text"
                                name=""
                                id=""
                                className="form-control"
                                value={interview.companyName}
                                disabled

                            />



                        </div>

                        <div className="col-md-6">


                            <p className="mb-0 fs-6">Job Profile</p>
                            <input
                                type="text"
                                name=""
                                id=""
                                className="form-control"
                                value={interview.jobProfile}
                                disabled

                            />
                        </div>


                        <div className="col-md-6">
                            <p className="mb-0 fs-6">Candidate Status</p>

                            <input
                                type="text"
                                name=""
                                id=""
                                className="form-control"
                                value={interview.candidateStatus}
                                disabled

                            />
                        </div>

                        <div className="col-md-6">
                            <p className="mb-0 fs-6">Joining Date</p>

                            <input
                                type="date"
                                name=""
                                id=""
                                className="form-control"
                                value={interview.joiningDate}
                                onChange={(e) => setInterView(prev => ({ ...prev, joiningDate: e.target.value }))}


                            />
                        </div>

                        <div className="col-md-6">
                            <p className="mb-0 fs-6">Consultancy Amount</p>

                            <input
                                type="text"
                                name=""
                                id=""
                                className="form-control"
                                value={interview.consultAmount}
                                onChange={(e) => setInterView(prev => ({ ...prev, consultAmount: e.target.value }))}


                            />
                        </div>


                        <div className="col-md-12">
                            <p className="mb-0 fs-6">Received Amount</p>

                            <input
                                type="text"
                                name=""
                                id=""
                                className="form-control"
                                value={interview.recvAmount}
                                onChange={(e) => setInterView(prev => ({ ...prev, recvAmount: e.target.value }))}


                            />
                        </div>

                        <div className="col-md-12">
                            <p className="mb-0 fs-6">Balance: {Number(interview.consultAmount) > 0 ? Number(interview.consultAmount) - Number(interview.recvAmount) : 0}</p>


                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} size="sm">
                        Close
                    </Button>
                    {_.isNil(balance) ? (
                        <Button variant="primary" onClick={handleAddOnboarding} size="sm">
                            Add Onboarding
                        </Button>
                    ) : (
                        <Button variant="primary" onClick={handleSubmit} size="sm">
                            Update Onboarding
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CandidateOnboarding