import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { SiMicrosoftexcel } from "react-icons/si";
import filterFactory, {
  textFilter,
  selectFilter,
  dateFilter
} from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search, CSVExport, pagination } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FaWhatsapp, FaDownload } from "react-icons/fa";
import { MdEdit, MdOutlineDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import candidateUpload from "../../assets/files/ATSCandidate.xlsx";
import edMantra from "../../services/edMantra";
import _ from "lodash";
import loadinggif from "../../assets/images/loading.gif";
import {
  FaFileDownload,
  FaFileExport,
  FaAddressCard,
  FaCloudUploadAlt,
  FaFilter,
} from "react-icons/fa";
import axios from "axios";

const CandidateManagement = (props) => {
  const sourceOptions = [
    "Activities",
    "Admission Centre",
    "Alumni",
    "Coaching's",
    "Dizital Mantras",
    "DM Student",
    "Employee Reference",
    "Employment Mantras",
    "Event",
    "Field Data",
    "Franchise",
    "GMB",
    "Google Ad",
    "Incoming Call",
    "Intern",
    "Job Fair",
    "Just Dial",
    "LinkedIn",
    "Meta Ad",
    "Naukari Portal",
    "Purchased Data",
    "Reference",
    "Schools",
    "Self-Generated",
    "Seminar",
    "Walk-In",
    "Website",
    "Webinar",
    "WhatsApp",
    "WhatsApp Group",
    "WhatsApp Status",
  ];
  const { data } = useSelector((state) => state?.currentSession?.userInfo);
  const [aadharFile, setAadharFile] = useState(null);
  const [agreementFile, setAgreementFile] = useState(null);
  const [resumeFile, setResumeFile] = useState(null);
  const [photo, setphoto] = useState(null);
  const [showModal, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [reload, setReload] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const fileInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSource, setSelectedSource] = useState('');
  const [file, setFile] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [personName, setpersonName] = useState('');
  const [personContact, setpersonContact] = useState('');
  const [candidate, setCandidate] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    maritalStatus: "",
    email: "",
    phone: "",
    dob: "",
    highestQualification: "",
    otherQualification: "",
    experience: "",
    sector: "",
    role: "",
    keySkills: "",
    language: [], // Initialize as an empty array
    employer: "",
    designation: "",
    yearsOfExperience: "",
    monthsOfExperience: "",
    currentCTC: "",
    expectedCTC: "",
    registeredDate: "",
    source: "",
    personName: "",
    personContact: "",
    residentialAddress: "",
    mailingAddress: "",
    residentialCity: "",
    mailingCity: "",

  });
  const [selectedLanguages, setSelectedLanguages] = useState({
    language: [], // Initialize as an empty array
  });

  const handleChange = (selectedOptions) => {
    setCandidate(prevState => ({
      ...prevState,
      language: selectedOptions ? selectedOptions.map(option => option.value) : [],
    }));
  };

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFileChange = (e, index) => {
    setFile(e.target.files[0]);
    setToggle(!toggle);
  };


  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", file);
  
    try {
      const result = await edMantra.uploadCandidate(formData);
  
      // If API call succeeds and no errors
      if (result.data.errorCode === 0) {
        setToggle(false);
        setRefresh(!refresh);
        toast.success(result?.data?.message);
        setFile({});
      }
    } catch (error) {
      // Default error message
      let errorMessage = "An unexpected error occurred. Please try again later.";
  
      // Check for a specific error response from the backend (response.data.message)
      if (error?.response?.data?.message) {
        // Check if it contains duplicate phone numbers
        if (error.response.data.errorCode === 1 && error.response.data.data?.duplicateNumbers) {
          const duplicateNumbers = error.response.data.data.duplicateNumbers;
          if (duplicateNumbers && duplicateNumbers.length > 0) {
            errorMessage = `Duplicate phone numbers found: ${duplicateNumbers.join(", ")}. Please remove them from the file and try again.`;
          }
        }
      }
  
      toast.error(errorMessage);  // Show the error message with duplicates
      console.error("Error uploading files:", error);
    }
  };
  




  const languageOptions = [
    { value: 'Assamese', label: 'Assamese' },
    { value: 'Bengali', label: 'Bengali' },
    { value: 'Bodo', label: 'Bodo' },
    { value: 'Dogri', label: 'Dogri' },
    { value: 'English', label: 'English' },
    { value: 'Gujarati', label: 'Gujarati' },
    { value: 'Hindi', label: 'Hindi' },
    { value: 'Kannada', label: 'Kannada' },
    { value: 'Kashmiri', label: 'Kashmiri' },
    { value: 'Konkani', label: 'Konkani' },
    { value: 'Maithili', label: 'Maithili' },
    { value: 'Malayalam', label: 'Malayalam' },
    { value: 'Manipuri', label: 'Manipuri' },
    { value: 'Marathi', label: 'Marathi' },
    { value: 'Nepali', label: 'Nepali' },
    { value: 'Odia', label: 'Odia' },
    { value: 'Punjabi', label: 'Punjabi' },
    { value: 'Sanskrit', label: 'Sanskrit' },
    { value: 'Santali', label: 'Santali' },
    { value: 'Sindhi', label: 'Sindhi' },
    { value: 'Tamil', label: 'Tamil' },
    { value: 'Telugu', label: 'Telugu' },
    { value: 'Urdu', label: 'Urdu' },
  ];

  const [candidateList, setCandidateList] = useState([]);
  const handleClose = () => {

    setShow(false);
    setIsEdit(false);
    setCandidate({
      firstName: "",
      lastName: "",
      gender: "",
      maritalStatus: "",
      email: "",
      phone: "",
      dob: "",
      highestQualification: "",
      otherQualification: "",
      experience: "",
      sector: "",
      role: "",
      employer: "",
      designation: "",
      yearsOfExperience: "",
      monthsOfExperience: "",
      currentCTC: "",
      expectedCTC: "",
      registeredDate: "",
      source: "",
      personName: "",
      personContact: "",
      residentialAddress: "",
      mailingAddress: "",
      residentialCity: "",
      mailingCity: "",

    });
  }


  const handleAadharDownlaod = async (fileName) => {
    console.log(fileName, "FileFileFileFileFileFile")
    try {
      //const response = await axios.post('http://localhost:5000/api/download',
          const response = await axios.post('https://mantraserp.com/api/download',
        { filename: fileName },
        { responseType: 'blob' } // Important for binary data
      );

      // Create a URL for the blob and trigger a download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // Set the default file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Download error:', error);
    }
  };


  const handleOnchange = (value, valueField) => {
    setCandidate((prev) => ({
      ...prev,
      [valueField]: value,
    }));

    if (valueField === "source") {
      setSelectedSource(value);
    }

    if (valueField === "personName") {
      setpersonName(value);
    }
    if (valueField === "personContact") {
      setpersonContact(value);
    }
  };

  const getAllCandidates = async () => {
    const result = await edMantra.getAllCandidates();
    setCandidateList(result?.data?.data);
  };

  useEffect(() => {
    getAllCandidates();
  }, [reload]);


  const handleDelete = async (row) => {
    await edMantra
      .deleteCandidateById(row)
      .then((res) => {
        toast(res?.data?.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setReload((reload) => !reload);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openWhatsApp = async (phone) => {
    try {
      if (!phone.startsWith("+91")) {
        phone = "+91" + phone; // Modify the existing phone variable
      }
      const whatsappUrl = `https://wa.me/${phone}`;
      window.open(whatsappUrl, "_blank");
    } catch (error) {
      console.error(error);
    }
  };



  const handleEdit = (row) => {
    setCandidate(row);
    setShow(true);
    setIsEdit(true);
  };



  const formatDateforleadinout = (dateString) => { //for lead craetedAt and updatedAt
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true // Include this option for 12-hour format
    };
    return date.toLocaleDateString('en-GB', options);
  };


  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "firstName",
      text: "Full Name",
      filter: textFilter(),
      formatter: (cell, row) => (
        <p>
          {row?.firstName || row?.lastName
            ? `${row?.firstName || ""} ${row?.lastName || ""}`.trim()
            : ""}
        </p>
      ),
    }
    ,
    {
      dataField: "phone",
      text: "Phone No.",
      filter: textFilter(),
    },

    {
      dataField: "email",
      text: "Email.",
      filter: textFilter(),
    },

    {
      dataField: "sector",
      text: "Industry",
      filter: textFilter(),
    },
    {
      dataField: "role",
      text: "Role",
      filter: textFilter(),
    },
    {
      dataField: "yearsOfExperience",
      text: "Years Of Experience",
      filter: textFilter(),
    },
    {
      dataField: "currentCTC",
      text: "Current CTC",
      filter: textFilter(),
    },
    {
      dataField: "mailingCity",
      text: "Mailing City",
      filter: textFilter(),
    },

    {
      dataField: "aadharDocName",
      text: "Aadhar",
      formatter: (cell, row) => (
        <div className="d-flex">
          <a href="#" onClick={() => handleAadharDownlaod(row.aadharDocName)}>{row.aadharDocName} <FaDownload /></a>
        </div>
      ),
    },

    {
      dataField: "agreementDocName",
      text: "Agreement",
      formatter: (cell, row) => (
        <div className="d-flex">
          <a href="#" onClick={() => handleAadharDownlaod(row.agreementDocName)}>{row.agreementDocName} | <FaDownload /></a>
        </div>
      ),
    },
    {
      dataField: "resumeDocName",
      text: "Resume",
      formatter: (cell, row) => (
        <div className="d-flex">
          <a href="#" onClick={() => handleAadharDownlaod(row.resumeDocName)}>{row.resumeDocName}</a>
        </div>
      ),
    },
    {
      dataField: "latestPhoto",
      text: "Photo",
      formatter: (cell, row) => (
        <div className="d-flex">
          <a href="#" onClick={() => handleAadharDownlaod(row.latestPhoto)}>{row.latestPhoto}</a>
        </div>
      ),
    },
    {
      dataField: 'createdAt',
      text: 'Create Time',
      sort: 'true',
      filter: dateFilter(),
      formatter: (cell, row) => formatDateforleadinout(cell)
    }, {
      dataField: 'updatedAt',
      text: 'Update Time',
      sort: 'true',
      filter: dateFilter(),
      formatter: (cell, row) => formatDateforleadinout(cell)
    },


    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex">



          <FaWhatsapp
            onClick={() => openWhatsApp(row?.phone)}
            size={20}
            color="limegreen"
          />

          <MdEdit
            size={25}
            color="black"
            style={{ cursor: "pointer", marginRight: "10px" }}
            onClick={() => handleEdit(row)}
          />
          <MdOutlineDelete
            size={25}
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(row)}
          />
        </div>
      ),
    },
  ];

  const handleAddCandidate = async () => {
    const formData = new FormData();
    formData.append("resume", resumeFile);
    formData.append("aadhar", aadharFile);
    formData.append("agreement", agreementFile);
    formData.append("photo", photo);
    formData.append("firstName", candidate.firstName);
    formData.append("lastName", candidate.lastName);
    formData.append("gender", candidate.gender);
    formData.append("maritalStatus", candidate.maritalStatus);
    formData.append("email", candidate.email);
    formData.append("phone", candidate.phone);
    formData.append("dob", candidate.dob);
    formData.append("highestQualification", candidate.highestQualification);
    formData.append("otherQualification", candidate.otherQualification);
    formData.append("experience", candidate.experience);
    formData.append("sector", candidate.sector);
    formData.append("role", candidate.role);
    formData.append("keySkills", candidate.keySkills);
    formData.append("language", JSON.stringify(candidate.language)); // Serialize array to JSON string
    formData.append("employer", candidate.employer);
    formData.append("designation", candidate.designation);
    formData.append("yearsOfExperience", candidate.yearsOfExperience);
    formData.append("currentCTC", candidate.currentCTC);
    formData.append("expectedCTC", candidate.expectedCTC);
    formData.append("registeredDate", candidate.registeredDate);
    formData.append("source", candidate.source);
    formData.append("personName", candidate.personName);
    formData.append("personContact", candidate.personContact);
    formData.append("residentialAddress", candidate.residentialAddress);
    formData.append("residentialCity", candidate.residentialCity);
    formData.append("mailingAddress", candidate.mailingAddress);
    formData.append("mailingCity", candidate.mailingCity);

    try {

      //const response = await fetch("http://localhost:5000/api/registerCandidate", // for dev env.
        const response = await fetch("https://mantraserp.com/api/registerCandidate", // for ive production
        {
          method: "POST",
          body: formData,
        });

      if (!response.ok) {
        throw new Error("Failed to upload files");
      }

      const result = await response.json();
      if (result?.errorCode === 0) {
        setAadharFile(null);
        setAgreementFile(null);
        setResumeFile(null);
        setphoto(null);
        setShow(false)
        toast.success(result?.message);
        setShow(false);
        getAllCandidates();
        setCandidate({
          firstName: "",
          lastName: "",
          gender: "",
          maritalStatus: "",
          email: "",
          phone: "",
          dob: "",
          highestQualification: "",
          otherQualification: "",
          experience: "",
          sector: "",
          role: "",
          employer: "",
          designation: "",
          yearsOfExperience: "",
          monthsOfExperience: "",
          currentCTC: "",
          expectedCTC: "",
          registeredDate: "",
          source: "",
          personName: "",
          personContact: "",
          residentialAddress: "",
          mailingAddress: "",
          residentialCity: "",
          mailingCity: "",

        });
        getAllCandidates();  // Refresh candidate list
      } else {
        // Handle API-specific error message
        toast.error(result?.message || "Something went wrong");
      }

    }
    catch (error) {
      // User-friendly error messages
      let errorMessage = "An unexpected error occurred. Please try again later.";

      // Check if the error has a response with a message
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      } else if (error.message.includes("Failed to upload files")) {
        errorMessage = "The phone number you entered is already registered. Please use a different phone number.";
      } else if (error.message.includes("phone must be unique")) {
        errorMessage = "Unable to register the candidate at the moment. Please check your network or try again.";
      }

      toast.error(errorMessage);
      console.error("Error uploading files:", error);
    }


  };

  const handleEditCandidate = async () => {

    const formData = new FormData();
    formData.append("candidateId", candidate.candidateId);
    formData.append("resume", resumeFile);
    formData.append("aadhar", aadharFile);
    formData.append("agreement", agreementFile);
    formData.append("photo", photo);
    formData.append("firstName", candidate.firstName);
    formData.append("lastName", candidate.lastName);
    formData.append("gender", candidate.gender);
    formData.append("maritalStatus", candidate.maritalStatus);
    formData.append("email", candidate.email);
    formData.append("phone", candidate.phone);
    formData.append("dob", candidate.dob);
    formData.append("highestQualification", candidate.highestQualification);
    formData.append("otherQualification", candidate.otherQualification);
    formData.append("experience", candidate.experience);
    formData.append("sector", candidate.sector);
    formData.append("role", candidate.role);
    formData.append("keySkills", candidate.keySkills);
    formData.append("language", JSON.stringify(candidate.language)); // Serialize array to JSON string
    formData.append("employer", candidate.employer);
    formData.append("designation", candidate.designation);
    formData.append("yearsOfExperience", candidate.yearsOfExperience);
    formData.append("currentCTC", candidate.currentCTC);
    formData.append("expectedCTC", candidate.expectedCTC);
    formData.append("registeredDate", candidate.registeredDate);
    formData.append("source", candidate.source);
    formData.append("personName", candidate.personName);
    formData.append("personContact", candidate.personContact);
    formData.append("residentialAddress", candidate.residentialAddress);
    formData.append("residentialCity", candidate.residentialCity);
    formData.append("mailingAddress", candidate.mailingAddress);
    formData.append("mailingCity", candidate.mailingCity);
    // try {
    //   const result = await edMantra.updateCandidateById(candidate);
    //   if (result?.data?.errorCode === 0) {
    // toast.success(result?.data?.message);
    // setShow(false);
    // setIsEdit(false);
    // getAllCandidates();
    // setCandidate({
    //   firstName: "",
    //   lastName: "",
    //   gender: "",
    //   maritalStatus: "",
    //   email: "",
    //   phone: "",
    //   dob: "",
    //   highestQualification: "",
    //   otherQualification: "",
    //   experience: "",
    //   sector: "",
    //   role: "",
    //   employer: "",
    //   designation: "",
    //   yearsOfExperience: "",
    //   monthsOfExperience: "",
    //   currentCTC: "",
    //   expectedCTC: "",
    //   registeredDate: "",
    //   source: "",
    //   personName: "",
    //   personContact: "",
    //   residentialAddress: "",
    //   mailingAddress: "",
    //   residentialCity: "",
    //   mailingCity: "",

    // });
    //   }
    // } catch (error) {
    //   console.error(error);
    // }

    try {

      //const response = await fetch("http://localhost:5000/api/updateCandidateById", // for dev env.
        const response = await fetch("https://mantraserp.com/api/updateCandidateById", // for ive production
        {
          method: "POST",
          body: formData,
        });

      if (!response.ok) {
        throw new Error("Failed to upload files");
      }

      const result = await response.json();
      if (result?.errorCode === 0) {
        setAadharFile(null);
        setAgreementFile(null);
        setResumeFile(null);
        setphoto(null);
        toast.success(result?.data?.message);
        setShow(false);
        setIsEdit(false);
        getAllCandidates();
        setCandidate({
          firstName: "",
          lastName: "",
          gender: "",
          maritalStatus: "",
          email: "",
          phone: "",
          dob: "",
          highestQualification: "",
          otherQualification: "",
          experience: "",
          sector: "",
          role: "",
          employer: "",
          designation: "",
          yearsOfExperience: "",
          monthsOfExperience: "",
          currentCTC: "",
          expectedCTC: "",
          registeredDate: "",
          source: "",
          personName: "",
          personContact: "",
          residentialAddress: "",
          mailingAddress: "",
          residentialCity: "",
          mailingCity: "",

        });
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };
  // Function to handle row selection
  const handleRowSelect = (row, isSelected) => {

    setSelectedRows([...selectedRows, row])


  }; console.log(selectedRows)

  // Function to handle row selection for all rows
  const handleRowSelectAll = (isSelected, rows) => {
    // Update selectedRows state when all rows are selected or deselected
    console.log(rows)
    if (isSelected) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 card border-0">
            <p className="fs-3 fw-normal typed-text">Manage Candidate</p>
          </div>



          <div className="card col-md-6 col-sm-12 border-0">
            <div className="d-flex flex-wrap align-items-center">
              {toggle ? (
                <button
                  type="button"
                  className="btn btn-warning btn-sm mr-2"
                  onClick={handleUpload}
                >
                  <FaCloudUploadAlt color={"black"} size={20} /> Upload
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-warning btn-sm mr-2"
                    onClick={handleButtonClick}
                  >
                    <FaFileDownload color={"black"} size={20} /> <span className="">Import</span>
                  </button>

                  <a href={candidateUpload} download className="btn btn-warning btn-sm mr-2">
                    <FaFileExport color={"black"} size={20} /> Sample File
                  </a>

                  <button
                    type="button"
                    className="btn btn-warning btn-sm"
                    onClick={() => handleShow()}
                  >
                    <FaAddressCard color={"black"} size={20} /> Add Candidate
                  </button>

                </>
              )}
            </div>
          </div>



          <div className="card col-md-12 border-0">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <img src={loadinggif} alt="Loading" />
              </div>
            ) : (
              <ToolkitProvider
                keyField="companyId"
                data={candidateList}
                columns={columns}
                exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                search
              >
                {
                  props => (
                    <div>
                      <ExportCSVButton className="btn btn-success btn-sm mb-2 float-end" {...(props.csvProps || {})} filename="MantrasERP_ATS_Candidates_Report.csv">< SiMicrosoftexcel /> Export Record</ExportCSVButton>

                      <div className="table-responsive cus-table">
                        <BootstrapTable
                          {...props.baseProps}
                          selectedRows={selectedRows}
                          bootstrap4
                          keyField="companyId"
                          data={candidateList}
                          columns={columns}
                          striped
                          hover
                          condensed
                          filter={filterFactory()}
                          pagination={paginationFactory({ showTotal: true, })}
                          selectRow={{
                            mode: 'checkbox',
                            clickToSelect: true,
                            onSelect: handleRowSelect,
                            onSelectAll: handleRowSelectAll,
                          }}
                          filterPosition="top"
                        />
                      </div>
                    </div>
                  )
                }
              </ToolkitProvider>
            )}
          </div>





        </div>
      </div>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Modal show={showModal} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title><u>Candidate Registration Form</u></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          <h6 className="custom-h6">Personal Details</h6>
          <div className="row">
            <div className="col-md-4">
              <p className="mb-0 fs-6"> First Name</p>
              <input
                placeholder="Enter First Name"
                className="form-control py-0 "
                type="text"
                // //disabled={isEdit}
                value={candidate.firstName}
                onChange={(e) => handleOnchange(e.target.value, "firstName")}
              />
            </div>
            <div className="col-md-4">
              <p className="mb-0 fs-6"> Last Name</p>
              <input
                placeholder="Enter Last Name"
                className="form-control py-0 "
                type="text"
                // //disabled={isEdit}
                value={candidate.lastName}
                onChange={(e) => handleOnchange(e.target.value, "lastName")}
              />
            </div>



            <div className="col-md-4">
              <p className="mb-0 fs-6"> Gender</p>
              <select
                className="form-control py-0 "
                value={candidate.gender}
                onChange={(e) =>
                  handleOnchange(e.target.value, "gender")
                }
              // //disabled={isEdit}
              >
                <option value="" disabled>
                  Select your status
                </option>

                <option value=">Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6"> Marital Status</p>
              <select
                className="form-control py-0 "
                value={candidate.maritalStatus}
                onChange={(e) =>
                  handleOnchange(e.target.value, "maritalStatus")
                }
              // //disabled={isEdit}
              >
                <option value="" disabled>
                  Select your status
                </option>

                <option value="married">Married</option>
                <option value="unmarried">Unmarried</option>
              </select>
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6"> Email Address</p>
              <input
                placeholder="Enter Email Address"
                className="form-control py-0 "
                type="email"
                // //disabled={isEdit}
                value={candidate.email}
                onChange={(e) => handleOnchange(e.target.value, "email")}
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Mobile Number</p>
              <input
                placeholder="Enter Phone Number"
                className="form-control py-0 "
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                required
                value={candidate.phone}
                onChange={(e) => handleOnchange(e.target.value, "phone")}
              // //disabled={isEdit}
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">DOB</p>
              <input
                placeholder="Date of Birth"
                className="form-control py-0 "
                type="date"
                // //disabled={isEdit}
                value={candidate.dob}
                onChange={(e) => handleOnchange(e.target.value, "dob")}
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Highest Qualification</p>
              <input
                placeholder="Highest Qualification"
                className="form-control py-0 "
                type="text"
                // //disabled={isEdit}
                value={candidate.highestQualification}
                onChange={(e) =>
                  handleOnchange(e.target.value, "highestQualification")
                }
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Other Qualification</p>
              <input
                placeholder="Other Qualification"
                className="form-control py-0 "
                type="text"
                // //disabled={isEdit}
                value={candidate.otherQualification}
                onChange={(e) =>
                  handleOnchange(e.target.value, "otherQualification")
                }
              />
            </div>




            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Total Experience</p>
              <select
                className="form-control py-0 "
                value={candidate.experience}
                onChange={(e) => handleOnchange(e.target.value, "experience")}
              >
                <option value="">Select</option>
                <option value="0">0</option>
                <option value="0.6">0.6</option>
                {Array.from({ length: 99 }, (_, i) => i + 1).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>



            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Sector/Industry</p>
              <select
                placeholder="Sector or Industry"
                className="form-control py-0 "

                value={candidate.sector}
                onChange={(e) =>
                  handleOnchange(e.target.value, "sector")
                }
              >
                <option value="no selection">Select Sector</option>
                <option value="accounts_ca_cs_taxation">Accounts or CA or CS or Taxation</option>
                <option value="adhesives">Adhesives</option>
                <option value="agency_consulting">Agency or Consulting</option>
                <option value="agriculture">Agriculture</option>
                <option value="any_official">Any Official</option>
                <option value="automobile">Automobile</option>
                <option value="aviation">Aviation</option>
                <option value="banking">Banking</option>
                <option value="biotech_rnd_scientist">Biotech or R&D or Scientist</option>
                <option value="bpo">BPO</option>
                <option value="chemical">Chemical</option>
                <option value="distribution_supply_chain">Distribution & Supply Chain</option>
                <option value="education">Education</option>
                <option value="energy">Energy</option>
                <option value="engineering_projects">Engineering Projects</option>
                <option value="entertainment">Entertainment</option>
                <option value="export_import">Export or Import</option>
                <option value="finance_micro_finance">Finance or Micro Finance</option>
                <option value="financial_services">Financial Services</option>
                <option value="fmcg">FMCG</option>
                <option value="food">Food</option>
                <option value="glass">Glass</option>
                <option value="govt_sector">Govt. Sector</option>
                <option value="home_furnishing">Home Furnishing</option>
                <option value="hospital_healthcare">Hospital or Healthcare</option>
                <option value="hotels_restaurants">Hotels or Restaurants</option>
                <option value="immigration">Immigration</option>
                <option value="insurance">Insurance</option>
                <option value="it_hardware">IT Hardware</option>
                <option value="it_software">IT Software</option>
                <option value="manufacturing">Manufacturing</option>
                <option value="media_advertising">Media and Advertising</option>
                <option value="oil_lubricants">Oil & Lubricants</option>
                <option value="online_marketing_digital_marketing">Online Marketing or Digital Marketing</option>
                <option value="paint">Paint</option>
                <option value="pharmaceutical">Pharmaceutical</option>
                <option value="plastic_pet">Plastic or Pet</option>
                <option value="plywood_laminates">Plywood & Laminates</option>
                <option value="publishing_printing">Publishing & Printing</option>
                <option value="real_estate">Real Estate</option>
                <option value="retail">Retail</option>
                <option value="technology">Technology</option>
                <option value="telecom">Telecom</option>
                <option value="transport_logistics">Transport or Logistics</option>
                <option value="transport_tour_travel">Transport or Tour & Travel</option>
                <option value="warehouse_inventory">Warehouse or Inventory</option>
                <option value="other">Other</option>

              </select>
            </div>


            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Role & Specialization</p>
              <select
                placeholder="Role & Specialization"
                className="form-control py-0 "


                value={candidate.role}
                onChange={(e) =>
                  handleOnchange(e.target.value, "role")
                }
              >
                <option value="no selection">Select Role & Specialization</option>
                <option value="account_executive">Account Executive</option>
                <option value="account_manager">Account Manager</option>
                <option value="admission_coordinator">Admission Coordinator</option>
                <option value="admin_executive">Admin Executive</option>
                <option value="admin_manager">Admin Manager</option>
                <option value="area_sales_manager">Area Sales Manager (ASM)</option>
                <option value="article_assistant">Article Assistant</option>
                <option value="assistant_manager">Assistant Manager</option>
                <option value="auditor">Auditor</option>
                <option value="autocad_engineer">Autocad Engineer</option>
                <option value="backend_office_executive">Backend or Back Office Executive or Assistant Executive</option>
                <option value="banquet_manager">Banquet Manager</option>
                <option value="billing_executives">Billing Executives</option>
                <option value="block_officer">Block Officer</option>
                <option value="boiler_operator">Boiler Operator</option>
                <option value="brand_manager">Brand Manager</option>
                <option value="business_analyst">Business Analyst</option>
                <option value="business_development_executive">Business Development Executive (BDE)</option>
                <option value="business_development_manager">Business Development Manager (BDM)</option>
                <option value="cashier">Cashier</option>
                <option value="civil_engineer">Civil Engineer</option>
                <option value="clinical_assistants">Clinical Assistants</option>
                <option value="collection_executives">Collection Executives</option>
                <option value="commodity_dealer">Commodity Dealer</option>
                <option value="company_secretary">Company Secretary</option>
                <option value="computer_operator">Computer Operator</option>
                <option value="content_writer">Content Writer</option>
                <option value="counsellor">Counsellor</option>
                <option value="credit_manager">Credit Manager</option>
                <option value="customer_care_executive">Customer Care Executive (CCE)</option>
                <option value="customer_relationship_officer">Customer Relationship Officer</option>
                <option value="data_analyst">Data Analyst</option>
                <option value="deputy_manager">Deputy Manager</option>
                <option value="digital_marketer">Digital Marketer</option>
                <option value="electrical_engineer">Electrical Engineer</option>
                <option value="electrician">Electrician</option>
                <option value="equity_dealer">Equity Dealer</option>
                <option value="export_manager">Export Manager</option>
                <option value="fashion_designer">Fashion Designer</option>
                <option value="finance_executive">Finance Executive</option>
                <option value="finance_officer">Finance Officer</option>
                <option value="financial_advisor">Financial Advisor</option>
                <option value="food_beverages_executive">Food & Beverages Executive</option>
                <option value="food_beverages_manager">Food & Beverages Manager</option>
                <option value="front_office_executive">Front Office Executive</option>
                <option value="gm_unit_head">GM/Unit Head</option>
                <option value="graphics_designer">Graphics Designer</option>
                <option value="hotel_captain">Hotel Captain</option>
                <option value="hotel_manager">Hotel Manager</option>
                <option value="hr_executive">HR Executive</option>
                <option value="hr_manager">HR Manager</option>
                <option value="inventory_manager">Inventory Manager</option>
                <option value="it_security_coordinator">IT Security Coordinator</option>
                <option value="it_trainer">IT Trainer</option>
                <option value="iti_fitter">ITI Fitter</option>
                <option value="lab_technician">Lab Technician</option>
                <option value="law_officer">Law Officer</option>
                <option value="liasoning_manager">Liasoning Manager</option>
                <option value="logistic_manager">Logistic Manager</option>
                <option value="machine_operator">Machine Operator</option>
                <option value="maintenance_engineer">Maintenance Engineer</option>
                <option value="mechanical_engineer">Mechanical Engineer</option>
                <option value="medical_representative">Medical Representative</option>
                <option value="merchandiser">Merchandiser</option>
                <option value="mis_executive">MIS Executive</option>
                <option value="networking_manager">Networking Manager</option>
                <option value="nurse">Nurse</option>
                <option value="operation_manager">Operation Manager</option>
                <option value="pathologist">Pathologist</option>
                <option value="pharmacist">Pharmacist</option>
                <option value="placement_officer">Placement Officer</option>
                <option value="plant_planning_manager">Plant Planning Manager</option>
                <option value="plant_supervisor">Plant Supervisor</option>
                <option value="pro">PRO</option>
                <option value="production_manager">Production Manager</option>
                <option value="project_manager_team_leader">Project Manager or Team Leader</option>
                <option value="purchase_executive">Purchase Executive</option>
                <option value="quality_analyst">Quality Analyst</option>
                <option value="quality_control_process_control">Quality Control or Process Control</option>
                <option value="receptionist">Receptionist</option>
                <option value="regional_manager">Regional Manager</option>
                <option value="restaurant_manager">Restaurant Manager</option>
                <option value="safety_officer">Safety Officer</option>
                <option value="sales_manager_marketing_manager">Sales Manager or Marketing Manager</option>
                <option value="sales_marketing_executive">Sales or Marketing Executive</option>
                <option value="sap_erp_developer">SAP or ERP Developer</option>
                <option value="seo_executive_manager">SEO Executive or Manager</option>
                <option value="service_delivery_manager">Service Delivery Manager</option>
                <option value="site_engineer">Site Engineer</option>
                <option value="social_media_marketing">Social Media Marketing</option>
                <option value="soft_skill_trainer">Soft Skill Trainer</option>
                <option value="software_developer">Software Developer</option>
                <option value="store_manager">Store Manager</option>
                <option value="supervisor">Supervisor</option>
                <option value="t_d_manager">T&D Manager</option>
                <option value="teacher_lecturer_trainer">Teacher or Lecturer or Trainer</option>
                <option value="team_leader">Team Leader</option>
                <option value="technical_sales">Technical Sales</option>
                <option value="telecaller">Telecaller</option>
                <option value="video_editor">Video Editor</option>
                <option value="vice_president">Vice President</option>
                <option value="waiter_steward">Waiter or Steward</option>
                <option value="warehouse_manager">Warehouse Manager</option>
                <option value="web_designer">Web Designer</option>
                <option value="web_developer">Web Developer</option>
                <option value="zonal_manager">Zonal Manager</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="col-md-12 mt-2">
              <p className="mb-0 fs-6">Key Skills</p>
              <input
                placeholder="Role"
                className="form-control py-0 "
                type="text"
                // //disabled={isEdit}
                value={candidate.keySkills}
                onChange={(e) => handleOnchange(e.target.value, "keySkills")}
              />
            </div>
            <div className="col-md-12 mt-2">
              <p className="mb-0 fs-6">Languages</p>
              <select
                isMulti
                value={languageOptions.filter(option => candidate.language && candidate.language.includes(option.value))}
                onChange={handleChange}
                options={languageOptions}
                className="basic-multi-select"
                classNamePrefix="select"
              />

            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Aadhar Doc</p>
              <input
                className="form-control py-0 "
                type="file"
                accept=".pdf,.doc,.docs"
                name="aadharDoc"

                onChange={(e) => setAadharFile(e.target.files[0])}
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Agreement Doc</p>
              <input
                className="form-control py-0 "
                type="file"

                accept=".pdf,.doc,.docs"
                name="agreementDoc"

                onChange={(e) => setAgreementFile(e.target.files[0])}
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Resume</p>
              <input
                className="form-control py-0 "
                type="file"

                accept=".pdf,.doc,.docs"
                name="resumeDoc"

                onChange={(e) => setResumeFile(e.target.files[0])}
              />
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Latest Photo</p>
              <input
                className="form-control py-0 "
                type="file"

                accept=".jpg, .jpeg, .png"
                name="photo"

                onChange={(e) => setphoto(e.target.files[0])}
              />
            </div>
          </div>

          <h6 className="custom-h6 mt-5">Experienced Details</h6>
          <div className="row">
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Employer/Company Name</p>
              <input
                placeholder="Company Name"
                className="form-control py-0 "
                type="text"
                // //disabled={isEdit}
                value={candidate.employer}
                onChange={(e) => handleOnchange(e.target.value, "employer")}
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Designation</p>
              <input
                placeholder="Designation"
                className="form-control py-0 "
                type="text"
                // //disabled={isEdit}
                value={candidate.designation}
                onChange={(e) => handleOnchange(e.target.value, "designation")}
              />
            </div>



            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Years Of Experience</p>
              <select
                className="form-control py-0 "
                value={candidate.yearsOfExperience}
                onChange={(e) => handleOnchange(e.target.value, "yearsOfExperience")}
              >
                <option value="">Select</option>
                <option value="0">0</option>
                <option value="0.6">0.6</option>
                {Array.from({ length: 99 }, (_, i) => i + 1).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>




            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Months Of Experience</p>
              <select
                className="form-control py-0 "
                value={candidate.monthsOfExperience}
                onChange={(e) => handleOnchange(e.target.value, "monthsOfExperience")}
              >
                <option value="">Select</option>
                <option value="0">0</option>
                {Array.from({ length: 99 }, (_, i) => i + 1).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6"> Current CTC</p>
              <input
                placeholder="Current CTC"
                className="form-control py-0 "
                type="text"
                //disabled={isEdit}
                value={candidate.currentCTC}
                onChange={(e) => handleOnchange(e.target.value, "currentCTC")}
              />
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6"> Expected CTC</p>
              <input
                placeholder="Current CTC"
                className="form-control py-0 "
                type="text"
                //disabled={isEdit}
                value={candidate.expectedCTC}
                onChange={(e) => handleOnchange(e.target.value, "expectedCTC")}
              />
            </div>
          </div>

          <h6 className="custom-h6 mt-5">Contact Information</h6>
          <div className="row">
            <div className="col-md-6">
              <p className="mb-0 fs-6">Registered Date</p>
              <input
                placeholder="Role"
                className="form-control py-0 "
                type="date"
                //disabled={isEdit}
                value={candidate.registeredDate}
                onChange={(e) =>
                  handleOnchange(e.target.value, "registeredDate")
                }
              />
            </div>

            <div className="col-md-6">
              <p className="mb-0 fs-6">Source</p>

              <select
                className="form-control py-0"
                value={candidate.source}
                onChange={(e) => handleOnchange(e.target.value, "source")}
              //disabled={isEdit}
              >
                <option value="">Select Source</option>
                {sourceOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {selectedSource === "Reference" && (
              <div className="d-flex mt-2">
                <div className="form-group col-md-6">
                  <p className="mb-0 fs-6">Person Name</p>
                  <input
                    type="text"
                    className="form-control"
                    value={candidate.personName}
                    placeholder="Refered By"
                    onChange={(e) => handleOnchange(e.target.value, "personName")}
                  />
                </div>
                <div className="form-group col-md-6">
                  <p className="mb-0 fs-6">Person Contact No.</p>
                  <input
                    type="text"
                    className="form-control"
                    value={candidate.personContact}
                    placeholder="Contact Number"
                    onChange={(e) => handleOnchange(e.target.value, "personContact")}
                  />
                </div>
              </div>
            )}




            <div className="col-md-6">
              <p className="mb-0 fs-6">Residential Address</p>
              <input
                placeholder="Residential Address"
                className="form-control py-0 "
                type="text"
                //disabled={isEdit}
                value={candidate.residentialAddress}
                onChange={(e) =>
                  handleOnchange(e.target.value, "residentialAddress")
                }
              />
            </div>
            <div className="col-md-6">
              <p className="mb-0 fs-6">Residential City</p>
              <input
                placeholder="Residential City"
                className="form-control py-0 "
                type="text"
                //disabled={isEdit}
                value={candidate.residentialCity}
                onChange={(e) =>
                  handleOnchange(e.target.value, "residentialCity")
                }
              />
            </div>
            <div className="col-md-6 mt-2">
              <p className="mb-0 fs-6">Mailing Address</p>
              <input
                placeholder="Mailing Address"
                className="form-control py-0 "
                type="text"
                //disabled={isEdit}
                value={candidate.mailingAddress}
                onChange={(e) =>
                  handleOnchange(e.target.value, "mailingAddress")
                }
              />
            </div>
            <div className="col-md-6 mt-2">
              <p className="mb-0 fs-6">Mailing City</p>
              <input
                placeholder="Mailing City"
                className="form-control py-0 "
                type="text"
                //disabled={isEdit}
                value={candidate.mailingCity}
                onChange={(e) =>
                  handleOnchange(e.target.value, "mailingCity")
                }
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} size="sm">
            Close
          </Button>
          {isEdit ? (
            <Button variant="primary" onClick={handleEditCandidate} size="sm">
              Update Candidate
            </Button>
          ) : (
            <Button variant="primary" onClick={handleAddCandidate} size="sm">
              Add Candidate
            </Button>
          )}
        </Modal.Footer>
      </Modal >

    </>
  );
};

export default CandidateManagement;