import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import './Globalstyle.css'
import edMantra from "../../services/edMantra";
import EDLeadForm from "../../Components/EDLeadForm/EDLeadForm";
import EDModal from "../../Components/EDModal/EDModal";
import { FaWhatsapp } from "react-icons/fa";
import { MdEdit, MdEmail } from "react-icons/md";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter';
import loadinggif from '../../assets/images/loading.gif'
import ToolkitProvider, { Search, CSVExport, pagination } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';



const Archive = () => {

    const { data } = useSelector((state) => state?.currentSession?.userInfo);
    console.log(data);

    const [allEmployees, setAllEmployees] = useState([]);
    const [leadData, setLeadData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    // State to store the IDs of selected leads
    const [selectedLeadIds, setSelectedLeadIds] = useState([]);


    const getDeadLead = async () => {
        try {
            let leads
            if (data.role.roleName === "AdminDm") {

                leads = await edMantra.getDeadLeads();
                if (leads) {
                    setLeadData(leads?.data?.data);
                } else {
                    setLeadData([])
                }
            }
            else {
                leads = await edMantra.getDeadLeads(data?.empId);

                if (leads) {
                    setLeadData(leads?.data?.data);
                } else {
                    setLeadData([])
                }
            }
        } catch (error) {
            console.error(error, "error");
        }
    };

    const getAllEmployees = async () => {
        try {
            const allEmployees = await edMantra.getAllEmployees();
            setAllEmployees(allEmployees.data.data);
        } catch (err) {
            console.error(err, "Error fetching data");
        }
    };


    const handleRowDeselect = (row) => {
        // Remove the deselected row's ID from selectedLeadIds state
        setSelectedLeadIds(selectedLeadIds.filter(id => id !== row.LeadId));
    };



    const handleBulkDelete = async () => {
        try {
            // Assuming 'deleteBulkLead' is the correct API call for bulk delete
            const result = await edMantra.deleteBulkLead({ leads: selectedRows });

            if (result.data.errorCode === 0) {
                toast.success(result.data.message);
            } else {
                toast.error(result.data.message);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                // Handle known errors from server response
                toast.error(error.response.data.message || "Internal server error");
            } else {
                // Handle unknown errors
                toast.error("Internal server error");
            }
            console.error("Error in handleBulkDelete:", error);
        }
    };

    const handleBulkRecover = async () => {
        try {
          const result = await edMantra.recoverBulkLead({ leads: selectedRows });
    
          if (result.data.errorCode === 0) {
            toast.success(result.data.message);
          } else {
            toast.error(result.data.message);
          }
        } catch (error) {
          if (error.response && error.response.data) {
            // Handle known errors from server response
            toast.error(error.response.data.message || "Internal server error");
          } else {
            // Handle unknown errors
            toast.error("Internal server error");
          }
          console.error("Error in handleBulkDead:", error);
        }
      };


    useEffect(() => {
        getAllEmployees();
        getDeadLead();

    }, [refresh]);



    const customPagination = paginationFactory({
        showTotal: true,
        sizePerPageList: [
          { text: '50', value: 50 },
          { text: '100', value: 100 },
          { text: '500', value: 500 },
          { text: '1000', value: 1000 }, // ✅ Allow 1000 rows per page
          { text: '5000', value: 5000 }, // ✅ Allow 1000 rows per page
        ],
        sizePerPage: 50, // Default rows per page
      });
    const formatDateforleadinout = (dateString) => { //for lead craetedAt and updatedAt
        const date = new Date(dateString);
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true // Include this option for 12-hour format
        };
        return date.toLocaleDateString('en-GB', options);
    };


    const formatDatefornextfollow = (dateString) => {
        const date = new Date(dateString);
        const options = {

            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true // Include this option for 12-hour format
        };
        return date.toLocaleDateString('en-GB', options);
    };

    // -----------data table--------------------------------------------

    const columns = [{
        dataField: 'SrNo',
        text: 'Sr. No',
        sort: 'true',
        filter: textFilter()
    }, {
        dataField: 'fullName',
        text: 'Full Name',
        filter: textFilter()
    }, {
        dataField: 'mobile',
        text: 'Contact No.',
        filter: textFilter()
    },

    {
        dataField: 'city',
        text: 'City',
        filter: textFilter()
    },

    {
        dataField: 'query',
        text: 'Query',
        filter: textFilter()

    },
    {
        dataField: 'status',
        text: 'Status',
        filter: textFilter()

    }, {

        dataField: 'nextfollow',
        text: 'Next Follow Up Date',
        filter: dateFilter(),
        formatter: (cell, row) => formatDatefornextfollow(cell)

    }
        , {
        dataField: 'createdAt',
        text: 'Create Time',
        filter: dateFilter(),
        formatter: (cell, row) => formatDateforleadinout(cell)
    }, {
        dataField: 'updatedAt',
        text: 'Update Time',
        filter: dateFilter(),
        formatter: (cell, row) => formatDateforleadinout(cell)
    },
    {
        dataField: 'telecaller',
        text: 'Assign To',
        filter: selectFilter({
            options: allEmployees.map(emp => ({
                value: emp.empId,
                label: `${emp.fname} ${emp.lname}`
            })),
            placeholder: 'Select or Search by Name'
        }),
        formatter: (cell, row) => {
            const telecaller = allEmployees?.find(emp => emp?.empId === row?.telecaller);
            if (telecaller) {
                return `${telecaller.fname} ${telecaller.lname}`;
            } else {
                return 'Not Assigned'; // Or any other placeholder text
            }
        }
    }
        ,
    {
        dataField: 'source',
        text: 'Lead Source',
    },


    ];

 

    // Function to handle row selection
    const handleRowSelect = (row, isSelected) => {

        setSelectedRows([...selectedRows, row])


    }; console.log(selectedRows)

    // Function to handle row selection for all rows
    const handleRowSelectAll = (isSelected, rows) => {
        // Update selectedRows state when all rows are selected or deselected
        console.log(rows)
        if (isSelected) {
            setSelectedRows(rows);
        } else {
            setSelectedRows([]);
        }
    };

    //--------------data tabel--------------------------------------------

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3 card border-0">
                    <p className="fs-3 fw-normal manage-lead">Archive Leads</p>
                </div>


                <div className="card col-md-9 border-0">
                    <div className="d-flex flex-wrap align-items-right">




                        <select
                            className="form-select border-0 mb-2"
                            aria-label="Default select example"
                            onChange={(e) => {
                                // Handle the update action when "Dead Lead" is selected
                                if (e.target.value === "delete") {
                                    handleBulkDelete();
                                }
                                else if (e.target.value === "New Lead")
                                    {
                                        handleBulkRecover();
                                    }
                            }}
                        >
                            <option>Select Action</option>
                            {data?.role?.roleName === "SuperAdmin" &&(
                                 <option value="delete">Delete Selected Leads</option>)}
                            <option value="New Lead">Recover Selected Leads</option>
                        </select>





                    </div>
                </div>

                <div className=" card col-md-12 border-0">

                    {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <img src={loadinggif} alt="Loading" />
                        </div>
                    ) : (



                        <ToolkitProvider
                        keyField="SrNo"
                        data={leadData}
                        columns={columns}
                       
                        search
                      >
                        {
                          props => (
                            <div>
                              
        
                              <div className="table-responsive cus-table">
                                <BootstrapTable
                                  {...props.baseProps}
                                  selectedRows={selectedRows}
                                  filter={filterFactory()}
                                  bootstrap4
                                  keyField='SrNo'
                                  data={leadData}
                                  columns={columns}
                                  striped
                                  hover
                                  condensed
                                  pagination={customPagination} // ✅ Apply custom pagination here
                                  selectRow={{
                                    mode: 'checkbox',
                                    clickToSelect: true,
                                    onSelect: handleRowSelect,
                                    onSelectAll: handleRowSelectAll,
                                  }}
                                  filterPosition="top"
                                />
                              </div>
                            </div>
                          )
                        }
                      </ToolkitProvider>






                    )} </div>


                <div className="card col-md-12 border-0">


                </div>

            </div>
        </div>
    );
};

export default Archive;